import Alpine from "alpinejs"

import { isntNil } from "~/src/lib/any"

type Preference = {
  name: string
  required: boolean
  response?: string
  preferenceResponseId?: string
  parentProps?: ParentProperties
}

type AddedPreference = { key: string } & Partial<Preference>

type PreferenceRecord = { [key: string]: Preference }

type ParentProperties = { id: string; matchingPreferenceResponseId: string; matchingResponse: string }

type RecipientGiftsState = {
  invalidPreferences: Preference[]
  preferences: PreferenceRecord
  init: () => void
  addPreference: (fields: AddedPreference) => void
  clearResponses: () => void
  validate: (preference: Preference) => boolean
  isValidPreferences: () => boolean
  displayName: (preference: Preference) => string
  registerPreference: { ["x-init"]: () => void }
}

export function recipientGiftsState(alpine: typeof Alpine) {
  alpine.data("recipientGiftsState", function (): RecipientGiftsState {
    return {
      invalidPreferences: [],
      preferences: {
        default_variant: {
          name: "Default",
          required: false,
          response: "ok",
        },
      },

      init() {
        this.$watch("preferences", (prefs: { [key: string]: Preference }) => {
          this.invalidPreferences = Object.values(prefs).filter((pref) => !this.validate(pref))
        })
      },

      registerPreference: {
        ["x-init"]() {
          this.addPreference(JSON.parse(this.$el.dataset.props))
        },
      },

      addPreference(fields) {
        const { key, response, preferenceResponseId, ...restPreference } = fields

        this.preferences[key] = {
          ...restPreference,
          response: response?.toString(),
          preferenceResponseId: preferenceResponseId?.toString(),
        }
      },

      clearResponses() {
        const newPreferences: PreferenceRecord = {}

        Object.entries<Preference>(this.preferences).forEach(([key, preference]) => {
          newPreferences[key] = { ...preference, response: undefined, preferenceResponseId: undefined }
        })

        this.preferences = newPreferences
      },

      validate(preference) {
        const { required, response, preferenceResponseId, parentProps } = preference

        if (parentProps != null) {
          const parentPreference = this.preferences[parentProps.id]

          // Abort if child but parent can't be found
          if (parentPreference == null) return true

          // Abort if parent preference response for this child isn't selected
          if (parentPreference.preferenceResponseId != parentProps.matchingPreferenceResponseId) {
            return true
          }
        }

        // Always valid if not required
        if (required == false) return true

        // Valid if a response or a preferenceResponseId is set
        return (
          (preferenceResponseId != null && preferenceResponseId.trim() != "") ||
          (response != null && response.trim() != "")
        )
      },

      isValidPreferences(): boolean {
        return this.invalidPreferences.length == 0
      },

      displayName(preference: Preference): string {
        const { parentProps } = preference

        if (isntNil(parentProps)) {
          const parentPreference = this.preferences[parentProps.id]

          if (isntNil(parentPreference)) {
            return parentPreference.name + " - " + parentProps.matchingResponse + ": " + preference.name
          }
        }

        return preference.name
      },
    }
  })
}
